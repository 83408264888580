import { useMemo, useState } from "react";

import { Form } from "antd";

import { useUser } from "./useUser";
import { useUpdateCurrentUserMutation } from "../store/auth/api";
import { getUserInitials } from "../utils/strings";

export const useProfile = () => {
    const { user } = useUser();

    const [form] = Form.useForm();

    const [isEditing, setIsEditing] = useState(false);

    const initials = useMemo(() => {
        return user && getUserInitials(user.info.firstName, user.info.lastName);
    }, [user]);

    const [updateCurrentUser, {
        isLoading,
    }] = useUpdateCurrentUserMutation();

    return {
        user,
        initials,
	    form,
        isLoading,
        isEditing,
        setIsEditing,
        updateCurrentUser,
    };
};
