import { lazy, ComponentType, LazyExoticComponent } from "react";

interface IRouteItem {
    id: number | string;
    path: string;
    component: LazyExoticComponent<ComponentType<any>>;
    isPrivate: boolean;
}

export const ROUTES: IRouteItem[] = [
    {
        id: 1,
        path: "/",
        component: lazy(() => import("./Dashboard")),
        isPrivate: true,
    },
    {
        id: 2,
        path: "/sign-up",
        component: lazy(() => import("./SignUp")),
        isPrivate: false,
    },
    {
        id: 3,
        path: "/auth",
        component: lazy(() => import("./Auth")),
        isPrivate: false,
    },
    {
        id: 4,
        path: "/organizations",
        component: lazy(() => import("./Organizations/Organizations")),
        isPrivate: true,
    },
    {
        id: 5,
        path: "/organizations/:id/*",
        component: lazy(() => import("./Organizations/OrganizationInfo")),
        isPrivate: true,
    },
    {
        id: 6,
        path: "/projects",
        component: lazy(() => import("./Projects/Projects")),
        isPrivate: true,
    },
    {
        id: 7,
        path: "/projects/:id/*",
        component: lazy(() => import("./Projects/ProjectInfo")),
        isPrivate: true,
    },
    {
        id: 8,
        path: "/projects/:id/personnel/:personnelId/*",
        component: lazy(() => import("./Personnel/PersonnelInfo")),
        isPrivate: true,
    },
    {
        id: 9,
        path: "/projects/:id/clients/:clientId/*",
        component: lazy(() => import("./Clients/ClientInfo")),
        isPrivate: true,
    },
    {
        id: 10,
        path: "/clients",
        component: lazy(() => import("./Clients/Clients")),
        isPrivate: true,
    },
    {
        id: 11,
        path: "/clients/:clientId/*",
        component: lazy(() => import("./Clients/ClientInfo")),
        isPrivate: true,
    },
    {
        id: 12,
        path: "/managers",
        component: lazy(() => import("./Managers/Managers")),
        isPrivate: true,
    },
    {
        id: 13,
        path: "/managers/:id/*",
        component: lazy(() => import("./Managers/ManagerInfo")),
        isPrivate: true,
    },
    {
        id: 14,
        path: "/profile",
        component: lazy(() => import("./Profile")),
        isPrivate: true,
    },
    {
        id: 15,
        path: "/reset-password",
        component: lazy(() => import("./ResetPassword")),
        isPrivate: false,
    },
    {
        id: 16,
        path: "/set-password",
        component: lazy(() => import("./SetPassword")),
        isPrivate: false,
    },
];
